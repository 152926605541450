<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <ComponentLoader v-if="busy"></ComponentLoader>
        <form @submit="submit" class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-header">
                <div class="modal-title">{{ $t("Buy NFT") }}</div>
                <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label>{{ $t("Wallet address") }}: {{ wallet }}</label>
                </div>
                <div class="form-group">
                    <label>{{ $t("Wallet password") }}:</label>
                    <input type="password" name="wallet_password" v-model="password" maxlength="255" class="form-control form-control-full-width auto-focus" :disabled="busy" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-mr" :disabled="busy || !password">
                    <i class="fas fa-check"></i> {{ $t("Buy") }}
                </button>
                <button type="button" class="btn btn-primary" @click="close">
                    {{ $t("Cancel") }}
                </button>
                <div class="form-error">{{ error }}</div>
            </div>
        </form>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { Request } from "@asanrom/request-browser";
import ComponentLoader from "@/components/utils/ComponentLoader.vue";
import { HOME_ROUTE } from "@/app-events-plugin";

import { AuthController } from "@/control/auth";
import { ApiMarket } from "@/api/api-group-market";

export default defineComponent({
    components: {
        ComponentLoader,
    },
    name: "BuyNFTModal",
    emits: ["update:display", "done"],
    props: {
        display: Boolean,
        nftId: String,
        saleId: String,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function() {
        return {
            wallet: AuthController.Wallet ? AuthController.Wallet.address : "",
            password: "",

            busy: false,
            error: "",
        };
    },
    methods: {
        close: function () {
            this.$closeModal();
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        submit: function(e) {
            if (e) {
                e.preventDefault();
            }
            if (this.busy) {
                return;
            }

            this.busy = true;
            this.error = "";

            Request.Do(ApiMarket.PostMarketNftIdOffersaleDidBuy(this.nftId, this.saleId, { password: this.password }))
                .onSuccess(() => {
                    this.busy = false;

                    this.$showSnackBar(this.$t("NFT successfully purchased!"));
                    this.close();
                    
                    this.$balanceChanged();
                    this.$salesOffersListChanged();
                })
                .onCancel(() => {
                    this.busy = false;
                })
                .onRequestError((err, handleErr) => {
                    this.busy = false;
                    handleErr(err, {        
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        badRequest: () => {
                            this.error = this.$t("Bad request");
                        },
                        notFoundClinicalTrialNotFound: () => {
                            this.error = this.$t("Clinical Trial not found");
                        },
                        notFoundNftNotFound: () => {
                            this.error = this.$t("NFT not found");
                        },
                        notFound: () => {
                            this.error = this.$t("Not found");
                        },
                        notFoundOfferSaleNotFound: () => {
                            this.error = this.$t("Offer Sale not found");
                        },
                        badRequestUserAlreadyOwner: () => {
                            this.error = this.$t("User is already the owner");
                        },
                        badRequestInsufficientBalance: () => {
                            this.error = this.$t("Insufficient balance");
                        },
                        badRequestWrongPassword: () => {
                            this.error = this.$t("Wrong wallet password");
                        },
                        badRequestAmountInvalid: () => {
                            this.error = this.$t("Invalid amount");
                        },
                        badRequestOfferSaleAlreadyFinished: () => {
                            this.error = this.$t("Offer sale is already finished");
                        },
                        forbiddenNotInvestor: () => {
                            this.error = this.$t("Not investor");
                        },
                        forbiddenWalletNotFound: () => {
                            this.error = this.$t("Wallet not found");
                        },
                        forbidden: () => {
                            this.$showMessageModal(
                                this.$t("Access denied"),
                                this.$t("You lack the required permission to visit this page"),
                            );
                            this.$router.push({ name: HOME_ROUTE });
                        },
                        serverError: () => {
                            this.error = this.$t("Internal server error") + ". " + this.$t("You can try again by reloading the page");
                        },
                        networkError: () => {
                            this.error =
                                this.$t("Could not connect to the server") + ". " + this.$t("You can try again by reloading the page");
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    this.error = this.$t("Internal server error") + ". " + this.$t("You can try again by reloading the page");
                    console.error(err);
                    this.busy = false;
                });
        },

        toTimestamp(d: string) {
            if (!d) {
                return null;
            }
            try {
                const date = new Date(d);
                return date.getTime();
            } catch (ex) {
                console.error(ex);
                return null;
            }
        },
    },
    mounted: function () {
        if (this.display) {
            this.$autoFocus();
        }
    },
    watch: {
        display: function () {
            if (this.display) {
                this.$autoFocus();
            }
        },
    },
});
</script>

<style></style>
