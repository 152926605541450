<template>
    <div
        class="modal-container modal-container-corner modal-notifications no-transition"
        :class="{ hidden: !display }"
        tabindex="-1"
        role="dialog"
        :aria-hidden="!display"
        @click="close"
        @keydown="escapeToClose"
    >
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-body no-padding border-top">
                <div class="settings-list">
                    <a class="settings-list-item" @click="openAddBalance">
                        <div class="settings-list-item-icon">
                            <i class="fas fa-coins"></i>
                        </div>
                        <div class="settings-list-item-caption">
                            {{ $t("Add Balance") }}
                        </div>
                    </a>
                </div>
            </div>
            <div class="modal-body no-padding border-top">
                <div class="settings-list">
                    <a class="settings-list-item" @click="openWithdrawBalance">
                        <div class="settings-list-item-icon">
                            <i class="fas fa-rotate-left"></i>
                        </div>
                        <div class="settings-list-item-caption">
                            {{ $t("Withdraw Balance") }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { renderDateAndTime } from "@/utils/time-utils";

export default defineComponent({
    name: "BalanceDropdown",
    emits: ["update:display", "openModal"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            focusTrap: null as FocusTrap,
            displayStatus: useVModel(props, "display"),
        };
    },
    data: function () {
        return {
        };
    },
    methods: {
        open: function () {
            this.displayStatus = true;
        },

        close: function () {
            this.displayStatus = false;
        },

        openAddBalance: function() {
            this.$emit("openModal", "add-balance-open");
        },

        openWithdrawBalance: function() {
            this.$emit("openModal", "withdraw-balance-open");
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        clickOnEnter: function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                event.target.click();
            }
        },
        
        renderDateTime: function (t: number) {
            return renderDateAndTime(t, this.$t);
        },
    },
    mounted: function () {
        this.focusTrap = new FocusTrap(this.$el, this.close.bind(this), "top-bar-button-balance");

        if (this.display) {
            this.focusTrap.activate();
            nextTick(() => {
                this.$el.focus();
            });
        }
    },
    beforeUnmount: function () {
        this.focusTrap.destroy();
    },
    watch: {
        display: function () {
            if (this.display) {
                this.focusTrap.activate();
                nextTick(() => {
                    this.$el.focus();
                });
            } else {
                this.focusTrap.deactivate();
            }
        },
    },
});
</script>

<style scoped>
@import "@/style/notifications-modal.css";
</style>
