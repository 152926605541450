<template>
    <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">
        <div class="modal-dialog modal-lg" role="document" @click="stopPropagationEvent">
            <div class="modal-header">
                <div class="modal-title" v-if="loading">{{ $t("Loading NFT") }}...</div>
                <div class="modal-title" v-else-if="notFound">{{ $t("NFT not found") }}</div>
                <div class="modal-title" v-else>{{ $t("NFT Info") }}</div>
                <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
                    <i class="fas fa-times"></i>
                </button>
            </div>
            <div class="modal-body modal-body-profile">
                <ComponentLoader v-if="loading"></ComponentLoader>
                <div class="user-profile" v-if="!loading && !notFound">

                    <div v-if="clinicalTrial.title" class="">{{ $t("Title") }}: {{ clinicalTrial.title }}</div>
                    <div v-if="clinicalTrial.description" class="">{{ $t("Description") }}: {{ clinicalTrial.description }}</div>

                    <div v-if="clinicalTrial.started" class="">{{ $t("Clinical Trial Started") }}: {{ renderDate(clinicalTrial.started) }}</div>
                    <div v-if="clinicalTrial.created" class="">{{ $t("Clinical Trial Created") }}: {{ renderDate(clinicalTrial.created) }}</div>


                    <div v-if="nftValue" class="">{{ $t("Value") }}: {{ nftValue }}</div>
                    <div v-if="phase" class="">{{ $t("Phase") }}: {{ phase }}</div>
                    <div v-if="molecule" class="">{{ $t("Molecule") }}: {{ molecule }}</div>
                    <div v-if="therapeuticIndication" class="">{{ $t("Therapeutic Indication") }}: {{ therapeuticIndication }}</div>
    
                    <div v-if="created" class="">{{ $t("Created") }}: {{ renderDate(created) }}</div>
                    <div v-if="acquisitionDate" class="">{{ $t("Acquisition Date") }}: {{ renderDate(acquisitionDate) }}</div>
  
                    <div>
                        <h4>Company</h4>
                        <div v-if="clinicalTrial.company.username" class="">{{ $t("Username") }}: {{ clinicalTrial.company.username }}</div>
                        <div v-if="clinicalTrial.company.name" class="">{{ $t("Name") }}: {{ clinicalTrial.company.name }}</div>
                        <div v-if="clinicalTrial.company.image" class="">{{ $t("Name") }}: {{ clinicalTrial.company.image }}</div>
                    </div>
                </div>
                <div class="user-profile" v-else>
                    <div class="user-profile-header">
                        <div class="user-profile-details">
                            <div class="user-profile-name">{{ $t("NFT not found") }}</div>
                            <div class="user-profile-error">
                                {{ $t("The NFT you are looking for does not exist or was deleted from the platform.") }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" v-if="!loading && notFound">
                <button type="button" class="btn btn-primary" @click="close">
                    {{ $t("Close") }}
                </button>
            </div>
        </div>
    </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { Timeouts } from "@/utils/timeout";
import { Request } from "@asanrom/request-browser";
import { getUniqueStringId } from "@/utils/unique-id";
import { ApiNfts } from "@/api/api-group-nfts";
import ComponentLoader from "@/components/utils/ComponentLoader.vue";
import { renderDateAndTime } from "@/utils/time-utils";
import { getCurrency } from "@/control/app-preferences";

export default defineComponent({
    components: {
        ComponentLoader,
    },
    name: "NFTModal",
    emits: ["update:display"],
    props: {
        display: Boolean,
        tokenId: Number,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loading: true,
            notFound: false,

            currency: getCurrency(),
            
            id: "",            
            uid: "",
            nftValue: "",
            phase: "",
            molecule: "",
            therapeuticIndication: "",
            created: 0,
            acquisitionDate: 0,
            clinicalTrial: {
                id: "",
                company: {
                    id: "",
                    username: "",
                    name: "",
                    image: ""
                },
                title: "",
                description: "",
                started: 0,
                created: 0
            },
            onSale: false,
            onAuction: false,
        };
    },
    methods: {
        close: function () {
            this.$closeModal();
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        renderDate: function (date: number) {
            return renderDateAndTime(date, this.$t);
        },

        onCurrencyChange: function() {
            this.currency = getCurrency();
            this.load();
        },

        load: function () {
            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            this.loading = true;

            Request.Pending(this.loadRequestId, ApiNfts.GetNftsIdToken(this.tokenId, { currency: this.currency }))
                .onSuccess((nft) => {
                    this.loading = false;
                    this.notFound = false;
                    this.id = nft.id;            
                    this.uid = nft.uid;    
                    this.nftValue = nft.nftValue;    
                    this.phase = nft.phase;    
                    this.molecule = nft.molecule;    
                    this.therapeuticIndication = nft.therapeuticIndication;    
                    this.created = nft.created;    
                    this.acquisitionDate = nft.acquisitionDate;    
                    this.clinicalTrial.id = nft.clinicalTrial.id; 
                    this.clinicalTrial.company.id = nft.clinicalTrial.company.id; 
                    this.clinicalTrial.company.username = nft.clinicalTrial.company.username; 
                    this.clinicalTrial.company.name = nft.clinicalTrial.company.name; 
                    this.clinicalTrial.company.image = nft.clinicalTrial.company.image; 
                    this.clinicalTrial.title = nft.clinicalTrial.title; 
                    this.clinicalTrial.description = nft.clinicalTrial.description; 
                    this.clinicalTrial.started = nft.clinicalTrial.started;
                    this.clinicalTrial.created = nft.clinicalTrial.created;
                    this.onSale = nft.onSale;    
                    this.onAuction = nft.onAuction;    
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        badRequest: () => {
                            this.loading = false;
                            this.notFound = true;
                        },
                        badRequestInvalidTokenId: () => {
                            this.loading = false;
                            this.notFound = true;
                        },
                        notFoundClinicalTrialNotFound: () => {
                            this.loading = false;
                            this.notFound = true;
                        },
                        notFoundNftNotFound: () => {
                            this.loading = false;
                            this.notFound = true;
                        },
                        notFound: () => {
                            this.loading = false;
                            this.notFound = true;
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },
    },
    mounted: function () {
        if (this.display) {
            this.load();
            this.$autoFocus();
        }

        this.$listenOnAppEvent("currency-changed", this.onCurrencyChange.bind(this));
    },
    beforeUnmount: function () {
        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
    watch: {
        display: function () {
            if (this.display) {
                this.load();
                this.$autoFocus();
            }
        },
        tokenId: function () {
            this.load();
        },
    },
});
</script>

<style scoped>
.user-profile {
    display: block;
}

.user-profile-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
}

.user-profile-image {
    width: 240px;
    height: 240px;
    border-radius: 50%;
}

@media (max-width: 240px) {
    .user-profile-image {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }
}

.user-profile-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    background-color: var(--user-profile-image-background);
}

.user-profile-details {
    padding: 1rem;
    width: calc(100% - 240px);
}

.user-profile-details div {
    padding-bottom: 0.75rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width: 640px) {
    .user-profile-header {
        flex-direction: column;
    }

    .user-profile-details {
        width: 100%;
        align-items: flex-start;
    }
}

.user-profile-name {
    font-weight: bold;
    font-size: xx-large;
}

.user-profile-username {
    font-size: large;
    opacity: 0.75;
}

.detail-icon {
    width: 38px;
}

.user-profile-edit {
    padding-top: 1rem;
}

.modal-body-profile {
    min-height: 240px;
}
</style>
