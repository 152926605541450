// API bindings: notifications (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { NotificationStatus, Notification, NotificationReadBody } from "./definitions";

export class ApiNotifications {
    /**
     * Method: GET
     * Path: /notifications
     * Get notification status
     * @returns The request parameters
     */
    public static GetNotifications(): RequestParams<NotificationStatus, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/notifications`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /notifications/list
     * Lists notifications
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetNotificationsList(queryParams: GetNotificationsListQueryParameters): RequestParams<Notification[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/notifications/list` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /notifications/read
     * Set notifications read
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostNotificationsRead(body: NotificationReadBody): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/notifications/read`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /notifications/id/{id}
     * Deletes notification
     * @param id Notification ID
     * @returns The request parameters
     */
    public static DeleteNotificationsIdId(id: string): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/notifications/id/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Query parameters for GetNotificationsList
 */
export interface GetNotificationsListQueryParameters {
    /**
     * Timestamp of the oldest notification you have, to get more.
     */
    oldest?: number;
}

