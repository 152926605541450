<template>
    <div class="component-loader">
        <div class="loading-overlay-loader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ComponentLoader",
});
</script>

<style></style>
