<template>
  <div
        class="modal-container modal-container-corner no-transition"
        :class="{ hidden: !display }"
        tabindex="-1"
        role="dialog"
        :aria-hidden="!display"
        @click="close"
        @keydown="escapeToClose"
    >
    <div
      class="modal-dialog modal-md"
      role="document"
      @click="stopPropagationEvent"
    >
      <div class="modal-header">
        <div class="modal-title">{{ $t("Select a currency") }}</div>
        <button
          type="button"
          class="modal-close-btn"
          :title="$t('Close')"
          @click="goBack"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <div class="modal-body no-padding">
        <div class="settings-list">
          <div
            class="settings-list-item"
            v-for="c in availableCurrencies"
            :key="c.id"
            tabindex="0"
            @keydown="clickOnEnter"
            @click="changeCurrency(c.id)"
          >
            <div
              class="settings-list-item-icon"
              :class="{ 'check-invisible': c.id !== currency }"
            >
              <i class="fas fa-check"></i>
            </div>
            <div class="settings-list-item-caption">{{ c.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { getCurrency, setCurrency } from "@/control/app-preferences";

export default defineComponent({
  name: "ChangeCurrencyModal",
  emits: ["update:display", "openModal"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      currency: getCurrency(),
      availableCurrencies: [
        {
          id: "USD",
          name: "USD",
        },
        {
          id: "EUR",
          name: "EUR",
        },
      ],
    };
  },
  methods: {
    close: function () {
      this.displayStatus = false;
    },

    goBack: function () {
      this.close();
      this.$emit("openModal", "account-settings");
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    changeCurrency: function (l) {
      this.currency = l;
      setCurrency(l);
      this.close();
      this.goBack();
    },

  },
  mounted: function () {
    if (this.display) {
      this.$autoFocus();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$autoFocus();
      }
    },
  },
});
</script>

<style>
</style>
