<template>
  <ModalDialogContainer ref="modalContainer" v-model:display="displayStatus">    
    <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
      <div class="modal-header">
        <div class="modal-title">{{ $t("Add balance to your account") }}</div>
        <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close" :disabled="busy">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ $t("You are about to add money to your account balance.") }}</p>
        <div class="form-group">
          <label>{{ $t("Select a payment method you want to use") }}:</label>
          <select v-model="useMethod" class="form-control form-select form-control-full-width" :disabled="busy">
            <option :value="'card'">{{ $t("Use debit / credit card") }}</option>
          </select>
        </div>
        <div class="form-group">
          <label>{{ $t("Amount to add") }} ({{ currency }}):</label>
          <input type="number" maxlength="255" v-model.number="amount" class="form-control form-control-full-width" :disabled="busy">
        </div>
        <div class="form-group">
          <label>{{ $t("Wallet address") }}: {{ wallet }}</label>
        </div>
        <div class="form-group">
          <label>{{ $t("Wallet password") }}:</label>
          <input type="password" name="wallet_password" v-model="password" maxlength="255" class="form-control form-control-full-width auto-focus" :disabled="busy" />
        </div>
        <div class="form-error" v-if="error">{{ error }}</div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary btn-mr" @click="confirm" :disabled="busy || !password || !useMethod || amount < 0">
          <i class="fas fa-check"></i> {{ $t("Confirm") }}
        </button>
        <button type="button" class="btn btn-primary" @click="close">
          {{ $t("Cancel") }}
        </button>
      </div>
    </div>
  </ModalDialogContainer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "@/utils/v-model";
import { AuthController } from "@/control/auth";
import { Request } from "@asanrom/request-browser";

import { getCurrency } from "@/control/app-preferences";
import { ApiBalance } from "@/api/api-group-balance";
import { HOME_ROUTE } from "@/app-events-plugin";

export default defineComponent({
  name: "BalanceAddModal",
  emits: ["update:display", "done"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      wallet: AuthController.Wallet ? AuthController.Wallet.address : "",
      password: "",

      useMethod: "card",

      amount: 0,

      currency: getCurrency(),

      busy: false,
      error: "",
    };
  },
  methods: {
    close: function () {
      this.$closeModal();
    },

    escapeToClose: function (event) {
      if (this.busy) {
        return;
      }

      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    confirm: function () {
      if (this.busy) {
        return;
      }

      this.error = "";
      this.busy = true;

      Request.Do(ApiBalance.PostPaymentBalanceAdd({ amount: this.amount + " " + this.currency, method: this.useMethod === "card" ? "card" : null, password: this.password }))
        .onSuccess((res) => {
          this.busy = false;

          if (res.url) {
            location.replace(res.url);
            return;
          }

          this.$showMessageModal(
            this.$t("Success"), 
            this.$t("The charge has been successfully sent. After it has been verified, your balance will be increased. This can take several minutes depending on your payment method.")
          );

          this.close();
          this.$emit("done");
        })
        .onCancel(() => {
          this.busy = false;
        })
        .onRequestError((err, handleErr) => {
          this.busy = false;
          handleErr(err, {
            unauthorized: () => {
              this.$requireLogin();
            },
            badRequest: () => {
              this.error = this.$t("Bad request");
            },
            badRequestWrongPassword: () => {
              this.error = this.$t("Wrong wallet password");
            },
            badRequestInvalidAmount: () => {
              this.error = this.$t("Invalid amount");
            },
            badRequestInvalidPaymentMethod: () => {
              this.error = this.$t("Invalid payment method");
            },
            forbidden: () => {
              this.$showMessageModal(
                this.$t("Access denied"),
                this.$t("You lack the required permission to visit this page"),
              );
              this.$router.push({ name: HOME_ROUTE });
            },
            serverError: () => {
              this.error = this.$t("Internal server error") + ". " + this.$t("You can try again by reloading the page");
            },
            networkError: () => {
              this.error =
                this.$t("Could not connect to the server") + ". " + this.$t("You can try again by reloading the page");
              },
            });
        })
      .onUnexpectedError((err) => {
        this.error = this.$t("Internal server error") + ". " + this.$t("You can try again by reloading the page");
        console.error(err);
        this.busy = false;
      });
    },

    renderMethod: function (m): string {
      switch (m.type) {
        case "stripe":
          return this.$t("Use debit / credit card");
        default:
          return (m.name || "-")
      }
    },
  },
  mounted: function () {
    if (this.display) {
      this.$autoFocus();
    }
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$autoFocus();
      }
    },
  },
});
</script>

<style></style>
