// API bindings: balance (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { BalancePageData, CheckoutURLRedirect, AddBalanceBody, WithdrawBalanceBody, ExchangeRatioResponse } from "./definitions";

export class ApiBalance {
    /**
     * Method: GET
     * Path: /payment/balance
     * Get balance
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetPaymentBalance(queryParams: GetPaymentBalanceQueryParameters): RequestParams<BalancePageData, GetPaymentBalanceErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/payment/balance` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /payment/balance/add
     * Adds balance to user account
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostPaymentBalanceAdd(body: AddBalanceBody): RequestParams<CheckoutURLRedirect, PostPaymentBalanceAddErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/payment/balance/add`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "INVALID_AMOUNT", handler.badRequestInvalidAmount)
                    .add(400, "INVALID_PAYMENT_METHOD", handler.badRequestInvalidPaymentMethod)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /payment/balance/withdraw
     * Balance withdrawal
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostPaymentBalanceWithdraw(body: WithdrawBalanceBody): RequestParams<void, PostPaymentBalanceWithdrawErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/payment/balance/withdraw`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "*", handler.forbidden)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "INSUFFICIENT_BALANCE", handler.badRequestInsufficientBalance)
                    .add(400, "AMOUNT_TOO_SMALL", handler.badRequestAmountTooSmall)
                    .add(400, "INVALID_AMOUNT", handler.badRequestInvalidAmount)
                    .add(400, "UNVERIFIED_PAYMENT_METHOD", handler.badRequestUnverifiedPaymentMethod)
                    .add(400, "INVALID_PAYMENT_METHOD", handler.badRequestInvalidPaymentMethod)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /payment/ratio/{currency}
     * Get exchange ratio for a currency from the default platform currency
     * @param currency The currency (ISO)
     * @returns The request parameters
     */
    public static GetPaymentRatioCurrency(currency: string): RequestParams<ExchangeRatioResponse, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/payment/ratio/${encodeURIComponent(currency)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetPaymentBalance
 */
export type GetPaymentBalanceErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetPaymentBalance
 */
export interface GetPaymentBalanceQueryParameters {
    /**
     * Currency to use
     */
    currency?: string;
}

/**
 * Error handler for PostPaymentBalanceAdd
 */
export type PostPaymentBalanceAddErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_PAYMENT_METHOD
     */
    badRequestInvalidPaymentMethod: () => void;

    /**
     * Handler for status = 400 and code = INVALID_AMOUNT
     */
    badRequestInvalidAmount: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

/**
 * Error handler for PostPaymentBalanceWithdraw
 */
export type PostPaymentBalanceWithdrawErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_PAYMENT_METHOD
     */
    badRequestInvalidPaymentMethod: () => void;

    /**
     * Handler for status = 400 and code = UNVERIFIED_PAYMENT_METHOD
     */
    badRequestUnverifiedPaymentMethod: () => void;

    /**
     * Handler for status = 400 and code = INVALID_AMOUNT
     */
    badRequestInvalidAmount: () => void;

    /**
     * Handler for status = 400 and code = AMOUNT_TOO_SMALL
     */
    badRequestAmountTooSmall: () => void;

    /**
     * Handler for status = 400 and code = INSUFFICIENT_BALANCE
     */
    badRequestInsufficientBalance: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;
};

