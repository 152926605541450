// API bindings: nfts (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonErrorHandler, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { NFTList, NFTItem, CreateNFTBody } from "./definitions";

export class ApiNfts {
    /**
     * Method: GET
     * Path: /nfts
     * List NFTs
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetNfts(queryParams: GetNftsQueryParameters): RequestParams<NFTList, GetNftsErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/nfts` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(403, "WALLET_NOT_VERIFIED", handler.forbiddenWalletNotVerified)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /nfts
     * Create a new NFT
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostNfts(body: CreateNFTBody): RequestParams<NFTItem, PostNftsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/nfts`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(500, "TRANSACTION_REVERTED", handler.serverErrorTransactionReverted)
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "NOT_OWNER", handler.forbiddenNotOwner)
                    .add(403, "NOT_COMPANY", handler.forbiddenNotCompany)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_VALUE", handler.badRequestInvalidValue)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /nfts/{id}
     * Gets NFT info
     * @param id NFT ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetNftsId(id: string, queryParams: GetNftsIdQueryParameters): RequestParams<NFTItem, GetNftsIdErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/nfts/${encodeURIComponent(id)}` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /nfts/{id}/token
     * Gets NFT info by token ID
     * @param id NFT ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetNftsIdToken(id: number, queryParams: GetNftsIdTokenQueryParameters): RequestParams<NFTItem, GetNftsIdTokenErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/nfts/${encodeURIComponent(id)}/token` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(400, "INVALID_TOKEN_ID", handler.badRequestInvalidTokenId)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetNfts
 */
export type GetNftsErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_VERIFIED
     */
    forbiddenWalletNotVerified: () => void;
};

/**
 * Query parameters for GetNfts
 */
export interface GetNftsQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;

    /**
     * User ID
     */
    uid?: string;

    /**
     * Currency to use
     */
    currency?: string;

    /**
     * Search filter
     */
    searchFilter?: string;

    /**
     * on-sale, off-sale or all
     */
    saleStatus?: string;
}

/**
 * Error handler for PostNfts
 */
export type PostNftsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_VALUE
     */
    badRequestInvalidValue: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = NOT_COMPANY
     */
    forbiddenNotCompany: () => void;

    /**
     * Handler for status = 403 and code = NOT_OWNER
     */
    forbiddenNotOwner: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;

    /**
     * Handler for status = 500 and code = TRANSACTION_REVERTED
     */
    serverErrorTransactionReverted: () => void;
};

/**
 * Error handler for GetNftsId
 */
export type GetNftsIdErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;
};

/**
 * Query parameters for GetNftsId
 */
export interface GetNftsIdQueryParameters {
    /**
     * Currency to use
     */
    currency?: string;
}

/**
 * Error handler for GetNftsIdToken
 */
export type GetNftsIdTokenErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = INVALID_TOKEN_ID
     */
    badRequestInvalidTokenId: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;
};

/**
 * Query parameters for GetNftsIdToken
 */
export interface GetNftsIdTokenQueryParameters {
    /**
     * Currency to use
     */
    currency?: string;
}

