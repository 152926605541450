// API bindings: market (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonErrorHandler, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { MarketplaceInfo, AuctionList, SalesOffersList, CreateSalesOfferResponse, CreateSalesOfferBody, BuyNFTBody, CreateAuctionResponse, CreateAuctionBody, CreateAuctionBidResponse, CreateAuctionBidBody } from "./definitions";

export class ApiMarket {
    /**
     * Method: GET
     * Path: /market
     * Get marketplace info
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetMarket(queryParams: GetMarketQueryParameters): RequestParams<MarketplaceInfo, CommonErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/market` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /market/auctions
     * List Auctions
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetMarketAuctions(queryParams: GetMarketAuctionsQueryParameters): RequestParams<AuctionList, CommonErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/market/auctions` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /market/sales-offers
     * List Sales Offers
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetMarketSalesoffers(queryParams: GetMarketSalesoffersQueryParameters): RequestParams<SalesOffersList, CommonErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/market/sales-offers` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /market/nft/{id}/offer-sale
     * Putting an NFT up for sale
     * @param id NFT ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostMarketNftIdOffersale(id: string, body: CreateSalesOfferBody): RequestParams<CreateSalesOfferResponse, PostMarketNftIdOffersaleErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/market/nft/${encodeURIComponent(id)}/offer-sale`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "NOT_OWNER", handler.forbiddenNotOwner)
                    .add(403, "WALLET_NOT_VERIFIED", handler.forbiddenWalletNotVerified)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "AMOUNT_INVALID", handler.badRequestAmountInvalid)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "NFT_ALREADY_SELLING_OUT", handler.badRequestNftAlreadySellingOut)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /market/nft/{id}/offer-sale/{did}/buy
     * Buy a NFT
     * @param id NFT ID
     * @param did Sale Offer ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostMarketNftIdOffersaleDidBuy(id: string, did: string, body: BuyNFTBody): RequestParams<void, PostMarketNftIdOffersaleDidBuyErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/market/nft/${encodeURIComponent(id)}/offer-sale/${encodeURIComponent(did)}/buy`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "OFFER_SALE_NOT_FOUND", handler.notFoundOfferSaleNotFound)
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "NOT_INVESTOR", handler.forbiddenNotInvestor)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INSUFFICIENT_BALANCE", handler.badRequestInsufficientBalance)
                    .add(400, "OFFER_SALE_ALREADY_FINISHED", handler.badRequestOfferSaleAlreadyFinished)
                    .add(400, "AMOUNT_INVALID", handler.badRequestAmountInvalid)
                    .add(400, "USER_ALREADY_OWNER", handler.badRequestUserAlreadyOwner)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /market/nft/{id}/auction
     * Puts an NFT up for auction
     * @param id NFT ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostMarketNftIdAuction(id: string, body: CreateAuctionBody): RequestParams<CreateAuctionResponse, PostMarketNftIdAuctionErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/market/nft/${encodeURIComponent(id)}/auction`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "NOT_OWNER", handler.forbiddenNotOwner)
                    .add(403, "WALLET_NOT_VERIFIED", handler.forbiddenWalletNotVerified)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "FINISH_DATE_INVALID", handler.badRequestFinishDateInvalid)
                    .add(400, "INITIAL_AMOUNT_INVALID", handler.badRequestInitialAmountInvalid)
                    .add(400, "NFT_ALREADY_SELLING_OUT", handler.badRequestNftAlreadySellingOut)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /market/nft/{id}/auction/{aid}/bid
     * Creates auction bid
     * @param id NFT ID
     * @param aid Auction ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static PostMarketNftIdAuctionAidBid(id: string, aid: string, body: CreateAuctionBidBody): RequestParams<CreateAuctionBidResponse, PostMarketNftIdAuctionAidBidErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/market/nft/${encodeURIComponent(id)}/auction/${encodeURIComponent(aid)}/bid`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "AUCTION_NOT_FOUND", handler.notFoundAuctionNotFound)
                    .add(404, "CLINICAL_TRIAL_NOT_FOUND", handler.notFoundClinicalTrialNotFound)
                    .add(404, "NFT_NOT_FOUND", handler.notFoundNftNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "NOT_INVESTOR", handler.forbiddenNotInvestor)
                    .add(403, "WALLET_NOT_FOUND", handler.forbiddenWalletNotFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INSUFFICIENT_BALANCE", handler.badRequestInsufficientBalance)
                    .add(400, "AUCTION_ALREADY_FINISHED", handler.badRequestAuctionAlreadyFinished)
                    .add(400, "AMOUNT_INVALID", handler.badRequestAmountInvalid)
                    .add(400, "USER_ALREADY_OWNER", handler.badRequestUserAlreadyOwner)
                    .add(400, "WRONG_PASSWORD", handler.badRequestWrongPassword)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Query parameters for GetMarket
 */
export interface GetMarketQueryParameters {
    /**
     * Currency to use
     */
    currency?: string;

    /**
     * Search filter
     */
    searchFilter?: string;
}

/**
 * Query parameters for GetMarketAuctions
 */
export interface GetMarketAuctionsQueryParameters {
    /**
     * Page. Starting by 1.
     */
    page?: number;

    /**
     * Search filter
     */
    searchFilter?: string;

    /**
     * Currency to use
     */
    currency?: string;
}

/**
 * Query parameters for GetMarketSalesoffers
 */
export interface GetMarketSalesoffersQueryParameters {
    /**
     * Currency to use
     */
    currency?: string;

    /**
     * Page. Starting by 1.
     */
    page?: number;

    /**
     * Search filter
     */
    searchFilter?: string;
}

/**
 * Error handler for PostMarketNftIdOffersale
 */
export type PostMarketNftIdOffersaleErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = NFT_ALREADY_SELLING_OUT
     */
    badRequestNftAlreadySellingOut: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = AMOUNT_INVALID
     */
    badRequestAmountInvalid: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_VERIFIED
     */
    forbiddenWalletNotVerified: () => void;

    /**
     * Handler for status = 403 and code = NOT_OWNER
     */
    forbiddenNotOwner: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;
};

/**
 * Error handler for PostMarketNftIdOffersaleDidBuy
 */
export type PostMarketNftIdOffersaleDidBuyErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = USER_ALREADY_OWNER
     */
    badRequestUserAlreadyOwner: () => void;

    /**
     * Handler for status = 400 and code = AMOUNT_INVALID
     */
    badRequestAmountInvalid: () => void;

    /**
     * Handler for status = 400 and code = OFFER_SALE_ALREADY_FINISHED
     */
    badRequestOfferSaleAlreadyFinished: () => void;

    /**
     * Handler for status = 400 and code = INSUFFICIENT_BALANCE
     */
    badRequestInsufficientBalance: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = NOT_INVESTOR
     */
    forbiddenNotInvestor: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;

    /**
     * Handler for status = 404 and code = OFFER_SALE_NOT_FOUND
     */
    notFoundOfferSaleNotFound: () => void;
};

/**
 * Error handler for PostMarketNftIdAuction
 */
export type PostMarketNftIdAuctionErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = NFT_ALREADY_SELLING_OUT
     */
    badRequestNftAlreadySellingOut: () => void;

    /**
     * Handler for status = 400 and code = INITIAL_AMOUNT_INVALID
     */
    badRequestInitialAmountInvalid: () => void;

    /**
     * Handler for status = 400 and code = FINISH_DATE_INVALID
     */
    badRequestFinishDateInvalid: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_VERIFIED
     */
    forbiddenWalletNotVerified: () => void;

    /**
     * Handler for status = 403 and code = NOT_OWNER
     */
    forbiddenNotOwner: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;
};

/**
 * Error handler for PostMarketNftIdAuctionAidBid
 */
export type PostMarketNftIdAuctionAidBidErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Handler for status = 400 and code = WRONG_PASSWORD
     */
    badRequestWrongPassword: () => void;

    /**
     * Handler for status = 400 and code = USER_ALREADY_OWNER
     */
    badRequestUserAlreadyOwner: () => void;

    /**
     * Handler for status = 400 and code = AMOUNT_INVALID
     */
    badRequestAmountInvalid: () => void;

    /**
     * Handler for status = 400 and code = AUCTION_ALREADY_FINISHED
     */
    badRequestAuctionAlreadyFinished: () => void;

    /**
     * Handler for status = 400 and code = INSUFFICIENT_BALANCE
     */
    badRequestInsufficientBalance: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * Handler for status = 403 and code = WALLET_NOT_FOUND
     */
    forbiddenWalletNotFound: () => void;

    /**
     * Handler for status = 403 and code = NOT_INVESTOR
     */
    forbiddenNotInvestor: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Handler for status = 404 and code = NFT_NOT_FOUND
     */
    notFoundNftNotFound: () => void;

    /**
     * Handler for status = 404 and code = CLINICAL_TRIAL_NOT_FOUND
     */
    notFoundClinicalTrialNotFound: () => void;

    /**
     * Handler for status = 404 and code = AUCTION_NOT_FOUND
     */
    notFoundAuctionNotFound: () => void;
};

