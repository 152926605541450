<template>
    <div
        class="modal-container modal-container-corner modal-notifications no-transition"
        :class="{ hidden: !display }"
        tabindex="-1"
        role="dialog"
        :aria-hidden="!display"
        @click="close"
        @keydown="escapeToClose"
    >
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-header-notifications">
                <h3>{{ $t("Notifications") }}</h3>
                <p v-if="unread > 0" class="clickable-text" @click="markRead">{{ $t("Mark as read") }}</p>
            </div>
            <div class="body-notifications">
                <p v-if="notifications.length === 0" class="no-notifications">{{ $t("No notifications available") }}</p>
                <div v-for="n in notifications" class="notification-item" :key="n.id">
                    <div class="notification-info">
                        <div class="notification-logo"><i class="fa-regular fa-bell"></i></div>

                        <div class="notification-data" v-if="n.data.type === 'kyc_accepted'">
                            <p>{{ $t("Your identity verification request was accepted.") }}</p>
                        </div>

                        <div class="notification-data" v-else-if="n.data.type === 'kyc_rejected'">
                            <p>{{ $t("Your identity verification request was rejected.") }}</p>
                            <p v-if="!n.data.reason">{{ $t("A reason was not provided.") }}</p>
                            <p v-if="n.data.reason">{{ $t("A detailed reason was provided") }}: {{ n.data.reason }}</p>
                        </div>

                        <div class="notification-data" v-else-if="n.data.type === 'transaction_sended'">
                            <p>
                                {{ $t("You have sended a transaction of") }} 
                                {{ n.data.amount }}
                                <span v-if="n.data.auctionId">{{ $t("to an auction") }}</span>
                                <span v-if="n.data.to" >
                                    {{ $t("to an") }}
                                    <a href="javascript:;" @click="showProfile(n.data.to)">{{ $t("user") }}</a>
                                </span>
                            </p>
                            <p v-if="n.data.nftId"><RouterLink :to="{ name: 'nft', params: { id: n.data.nftId } }">{{ $t("Show NFT") }}</RouterLink></p>
                            <p>{{ renderDateTime(n.date) }}</p>
                        </div>

                        <div class="notification-data" v-else-if="n.data.type === 'transaction_received'">
                            <p>
                                {{ $t("You have received a transaction of") }} 
                                {{ n.data.amount }}
                                <span v-if="n.data.auctionId">{{ $t("from an auction") }}</span>
                                <span v-if="n.data.from" >
                                    {{ $t("from an") }}
                                    <a href="javascript:;" @click="showProfile(n.data.from)">{{ $t("user") }}</a>
                                </span>
                            </p>
                            <p v-if="n.data.nftId"><RouterLink :to="{ name: 'nft', params: { id: n.data.nftId } }">{{ $t("Show NFT") }}</RouterLink></p>
                            <p>{{ renderDateTime(n.date) }}</p>
                        </div>

                        <div class="notification-data" v-else-if="n.data.type === 'nft_received'">
                            <p>
                                {{ $t("You have received a NFT") }} 
                            </p>
                            <p v-if="n.data.nftId"><RouterLink :to="{ name: 'nft', params: { id: n.data.nftId } }">{{ $t("Show NFT") }}</RouterLink></p>
                            <p>{{ renderDateTime(n.date) }}</p>
                        </div>

                        <div class="notification-data" v-else-if="n.data.type === 'nft_sended'">
                            <p>
                                {{ $t("You have sended a NFT") }} 
                            </p>
                            <p v-if="n.data.nftId"><RouterLink :to="{ name: 'nft', params: { id: n.data.nftId } }">{{ $t("Show NFT") }}</RouterLink></p>
                            <p>{{ renderDateTime(n.date) }}</p>
                        </div>

                        <div class="notification-data" v-else>
                            <p>{{ $t("Unknown notification.") }}</p>
                        </div>

                        <a @click="deleteNotification(n.id)" :title="$t('Remove')" class="btn-delete-notification">
                            <i class="fas fa-trash-alt"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";
import { useVModel } from "@/utils/v-model";
import { FocusTrap } from "@/utils/focus-trap";
import { renderDateAndTime } from "@/utils/time-utils";
import { getUniqueStringId } from "@/utils/unique-id";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";

import { AuthController } from "@/control/auth";
import { NotificationsController } from "@/control/notifications";
import { ApiNotifications } from "@/api/api-group-notifications";

export default defineComponent({
    name: "NotificationsDropdown",
    emits: ["update:display"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            focusTrap: null as FocusTrap,
            displayStatus: useVModel(props, "display"),
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loading: true,

            notifications: [],
            unread: NotificationsController.UnreadCount,
            busyRead: false,
            busyDelete: false,
        };
    },
    methods: {
        updateUnread: function () {
            this.unread = NotificationsController.UnreadCount;
        },

        open: function () {
            this.displayStatus = true;
        },

        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        clickOnEnter: function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                event.target.click();
            }
        },
        
        renderDateTime: function (t: number) {
            return renderDateAndTime(t, this.$t);
        },

        load: function() {
            this.loading = true;

            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            if (!AuthController.isAuthenticated()) {
                return;
            }

            this.notifications = [];

            let oldest = Date.now();

            if (this.notifications.length > 0) {
                oldest = this.notifications[this.notifications.length - 1].date;
            }

            Request.Pending(this.loadRequestId, ApiNotifications.GetNotificationsList({ oldest: oldest }))
                .onSuccess((response) => {
                    this.loading = false;
                    this.notifications = response;
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },

        markRead: function() {
            if (this.busyRead) {
                return;
            }
            this.busyRead = true;
            Request.Do(ApiNotifications.PostNotificationsRead({timestamp: Date.now() })).onSuccess(() => {
                this.busyRead = false;
                this.unread = 0;
                NotificationsController.Load();
            }).onRequestError(err => {
                console.error(err);
            }).onUnexpectedError(err => {
                this.busyRead = false;
                console.error(err);
            });

        },

        deleteNotification: function(nid: string) {
            if (this.busyDelete) {
                return;
            }
            this.busyDelete = true;
            Request.Do(ApiNotifications.DeleteNotificationsIdId(nid)).onSuccess(() => {
                this.busyDelete = false;
                this.load();
                NotificationsController.Load();
            }).onRequestError(err => {
                console.error(err);
            }).onUnexpectedError(err => {
                this.busyDelete = false;
                console.error(err);
            });
        },

        showProfile: function(uid: string) {
            this.$showProfileModal(uid);
        },
    },
    mounted: function () {
        this.load();

        this.focusTrap = new FocusTrap(this.$el, this.close.bind(this), "top-bar-button");

        if (this.display) {
            this.focusTrap.activate();
            nextTick(() => {
                this.$el.focus();
            });
        }
    },
    beforeUnmount: function () {
        this.focusTrap.destroy();

        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
    watch: {
        display: function () {
            if (this.display) {
                this.focusTrap.activate();
                nextTick(() => {
                    this.$el.focus();
                });
            } else {
                this.focusTrap.deactivate();
            }
        },
    },
});
</script>

<style scoped>
@import "@/style/notifications-modal.css";
</style>
